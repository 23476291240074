import {SocialNetworks} from '@wix/panda-js-utils'
import {SHARE_BUTTON_NAMES} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React, {KeyboardEvent} from 'react'
import {Facebook} from '../../../icons/Facebook'
import {Linkedin} from '../../../icons/Linkedin'
import {X} from '../../../icons/X'
import s from './social-bar.scss'
import {SocialBarProps} from '.'

const DEFAULT_SPACING = 14

const REFERENCE_FONT_SIZE = 22

export class SocialBar extends React.Component<SocialBarProps> {
  facebookShare = () => {
    const {share, onShare, eventId, eventUrl, t} = this.props
    share(eventUrl, SocialNetworks.Facebook, t)
    onShare && onShare(eventId, SHARE_BUTTON_NAMES.FACEBOOK)
  }

  xShare = () => {
    const {share, onShare, eventId, eventUrl, t} = this.props
    share(eventUrl, SocialNetworks.Twitter, t)
    onShare && onShare(eventId, SHARE_BUTTON_NAMES.X)
  }

  linkedInShare = () => {
    const {share, onShare, eventId, eventUrl, t} = this.props
    share(eventUrl, SocialNetworks.LinkedIn, t)
    onShare && onShare(eventId, SHARE_BUTTON_NAMES.LINKEDIN)
  }

  handleKeyPress = (event: KeyboardEvent<HTMLAnchorElement>, handler: Function) => {
    if (event.key === 'Enter') {
      handler()
    }
  }

  render() {
    const {t, hideTitle, spacing = DEFAULT_SPACING, row, className, iconFontSize} = this.props

    return (
      <div data-hook="social-bar" className={classNames({[s.row]: row}, className)}>
        {!hideTitle && <div className={row ? s.rowTitle : s.title}>{t('shareEventText')}</div>}
        <div className={s.icons}>
          <a
            className={s.icon}
            data-hook="social-bar-icon-facebook"
            onClick={this.facebookShare}
            onKeyPress={event => {
              this.handleKeyPress(event, this.facebookShare)
            }}
            tabIndex={0}
            aria-label={t('a11y.shareButtonLabel', {button: 'Facebook'})}
          >
            <Facebook
              size={undefined}
              width={`${getIconSize(9, iconFontSize)}px`}
              height={`${getIconSize(16, iconFontSize)}px`}
            />
          </a>
          <a
            style={{margin: `0 ${spacing}px`}}
            className={s.icon}
            data-hook="social-bar-icon-x"
            onClick={this.xShare}
            onKeyPress={event => {
              this.handleKeyPress(event, this.xShare)
            }}
            tabIndex={0}
            aria-label={t('a11y.shareButtonLabel', {button: 'X'})}
          >
            <X width={`${getIconSize(18, iconFontSize)}px`} height={`${getIconSize(14, iconFontSize)}px`} />
          </a>
          <a
            className={s.icon}
            data-hook="social-bar-icon-linkedin"
            onClick={this.linkedInShare}
            onKeyPress={event => {
              this.handleKeyPress(event, this.linkedInShare)
            }}
            tabIndex={0}
            aria-label={t('a11y.shareButtonLabel', {button: 'LinkedIn'})}
          >
            <Linkedin
              size={undefined}
              width={`${getIconSize(19, iconFontSize)}px`}
              height={`${getIconSize(16, iconFontSize)}px`}
            />
          </a>
        </div>
      </div>
    )
  }
}

const getIconSize = (defaultActualSize: number, requiredSize?: number) =>
  requiredSize ? Math.round(((defaultActualSize * requiredSize) / REFERENCE_FONT_SIZE) * 100) / 100 : defaultActualSize
