import {IMAGE_POSITION, hasImage} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React from 'react'
import {ImageResizeOptions, WowImage} from 'wix-ui-tpa/cssVars'
import {resolveFocalPoint} from '../../../../../commons/utils/image'
import s from './event-image.scss'
import {classes} from './event-image.st.css'
import {EventImageProps} from './interfaces'

export const EventImage = ({
  event,
  position = IMAGE_POSITION.CENTER_CENTER,
  className,
  singleLayout,
  noBackground,
  backgroundFallback,
  opacityFallback,
  noLQIP,
  seo,
}: EventImageProps) => {
  const shouldUseLQIP = !noLQIP
  const focalPoint = resolveFocalPoint(position)

  const getOpacityClass = () => {
    if (singleLayout && opacityFallback) {
      return classes.singleOpacity
    } else {
      if (opacityFallback) {
        return classes.multiOpacityWithFallback
      } else {
        return classes.multiOpacity
      }
    }
  }

  const getBackgroundClass = () => {
    if (singleLayout) {
      return s.singleBackground
    } else if (backgroundFallback) {
      return s.multiBackgroundWithFallback
    } else {
      return s.multiBackground
    }
  }

  const props = {
    'data-hook': DH.image,
    src: event.mainImage?.id,
    alt: event.title,
    sourceWidth: event.mainImage?.width,
    sourceHeight: event.mainImage?.height,
    focalPoint,
    shouldUseLQIP,
    fluid: true,
    isSEOBot: seo,
  }

  return (
    <>
      {!noBackground ? (
        <div className={classNames(s.container, getBackgroundClass())} data-hook="image-background" />
      ) : null}
      {hasImage(event) ? (
        <div className={classNames(s.container, className)}>
          <WowImage
            {...props}
            className={classNames(
              s.imageSizing,
              getOpacityClass(),
              singleLayout ? s.singleContainVisibility : s.multiContainVisibility,
            )}
            resize={ImageResizeOptions.contain}
          />
          <WowImage
            {...props}
            className={classNames(
              s.imageSizing,
              getOpacityClass(),
              singleLayout ? s.singleCoverVisibility : s.multiCoverVisibility,
            )}
            resize={ImageResizeOptions.cover}
          />
        </div>
      ) : null}
    </>
  )
}
